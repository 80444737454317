import React, { FunctionComponent } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import { TechStackDomainListType } from "../../types";
import { Translate } from "../translations";
import { PageHero } from "../page-hero";
import Section from "../section";
import TechStackAppList from './techStackList';
import TechStackCategories from './techStackCategories';
import { cleanURLSpace } from '../../helpers';
import Layout from '../../components/layout/layout';
import './styles.scss';

const TechStackByCategory: FunctionComponent<TechStackDomainListType> = ({ pageContext }) => {
  const {isDetails, name} = pageContext;
  const titleKeyword = isDetails ? 'TOP_USED_TECHNOLOGIES_FOR_CATEGORY_NAME_TITLE_DETAILS' : 'TOP_USED_TECHNOLOGIES_FOR_CATEGORY_NAME_TITLE';
  const subtitleKeyword = isDetails ? 'TOP_USED_TECHNOLOGIES_FOR_CATEGORY_NAME_SUBTITLE_DETAILS' : 'TOP_USED_TECHNOLOGIES_FOR_CATEGORY_NAME_SUBTITLE';
  return (
    <div className="App">
      <Layout>
        <PageHero
          title={<Translate name={titleKeyword} context={{ name }} />}
          subtitle={<Translate name={subtitleKeyword} context={{ name }} />}
        />

        <Section>
          <div className="col-md-3">
            <TechStackCategories vertical active={name} />
          </div>
          <div className="col-md-9">
            <div className="tech-stack-tabs">
              <Link
                to={`/most-popular-tech-stacks/${cleanURLSpace(name)}`}
                className={classNames({
                  active: !isDetails,
                })}
              >
                Category Apps
            </Link>
              <Link
                to={`/most-popular-tech-stacks/${cleanURLSpace(name)}/details`}
                className={classNames({
                  active: isDetails,
                })}
              >
                Also using with
            </Link>
            </div>
            <TechStackAppList pageContext={pageContext} />
          </div>
        </Section>
      </Layout>
    </div>
  );
}

export default TechStackByCategory;
